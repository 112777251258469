import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import Byline from "../components/Byline"
import CitationByline from "../components/CitationByline"
import Reflections from "../components/Reflections"
import GetUpdates from "../components/GetUpdates"

const ReflectionTemplate = ({ data }) => {
  const {
    authors,
    body,
    contentful_id,
    featuredImage,
    citationDate,
    slug,
    title,
  } = data.contentfulReflection
  const hero = getImage(featuredImage)
  const url = `https://thepolicylab.brown.edu/reflections/${slug}`

  return (
    <>
      <Seo title={title} bodyClass="layout__reflection presentation" description={body.childMarkdownRemark.excerpt} article={true} />
      <Layout>
        {featuredImage &&
          <div className="hero__wrapper">
            <GatsbyImage
              image={hero}
              alt=""
              className="hero__img"
            />
          </div>
        }
        <main id="content" className="layout__main s__white reflection">
          <div className="reflection__content">
            <header className="reflection__content__header u__spacing">
              <div className="row u__container">
                <div className="col-sm-7 reflection__content__title-wrapper">
                  <Link to="/reflections" className="reflection__content__landing-link">
                    Reflections
                  </Link>
                  <h1 className="reflection__content__title display-5 pt-4 mb-2">{title}</h1>
                </div>
              </div>
              <div className="row u__container">
                <div className="col-sm-8 reflection__content__authors-wrapper">
                  <p className="reflection__content__authors">
                    <Byline authors={authors} contentID={contentful_id} />
                  </p>
                </div>
              </div>
            </header>
            <article className="container u__container u__vspacing reflection__content__body">
              <div className="row">
                <div className="col-sm-9 col-lg-8" dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
                <div className="col-sm-9 col-lg-8 citation">
                  <hr />
                  <p>
                    <span>How to cite this Reflection: </span>
                    <CitationByline authors={authors} contentID={contentful_id} />
                    <span>{` (${citationDate}). ${title}.`} <em>The Policy Lab</em>. <a href={url}>{url}</a></span>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </main>
        <Reflections slug={slug} header="More Reflections" sectionClass="s__light" />
        <GetUpdates />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ReflectionQuery($slug: String!) {
    contentfulReflection(slug: { eq: $slug }) {
      authors {
        contentful_id
        name
        firstName
        lastName
        slug
      }
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      contentful_id
      featuredImage {
        ... on ContentfulAsset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      slug
      title
      citationDate: publishDate(formatString: "YYYY, MMMM DD")
    }
  }
`

export default ReflectionTemplate
