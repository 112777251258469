import React from "react"

export default function CitationByline(props) {
  return(
    <>
      {props.authors.map((author, index) => {
        const firstNameAbb = author.firstName.substring(0, 1)
        let authorName = ""
        if (index === props.authors.length - 1 && index !== 0) {
          authorName = ` & ${author.lastName} ${firstNameAbb}.`
        } else if (index === 0) {
          authorName = `${author.lastName} ${firstNameAbb}.`
        } else {
          authorName = `, ${author.lastName} ${firstNameAbb}.`
        }

        return (
          <span key={`${props.contentID}${author.contentful_id}`}>{authorName}</span>
        )
      })}
    </>
  )
}
