import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Byline from "../components/Byline"

export default function Reflections(props) {
  // used to .filter the current page out of the query
  // since variables cannot be passed to a static query
  const slug = props.slug

  // limit is set to 1 more than will display, in case 1 is filtered out by slug
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulReflection(
            limit: 5
            sort: {order: [DESC, DESC], fields: [featured, publishDate]}
          ) {
            nodes {
              title
              authors {
                contentful_id
                name
                slug
              }
              body {
                childMarkdownRemark {
                  excerpt(format: PLAIN, pruneLength: 200)
                }
              }
              contentful_id
              slug
              publishDate
            }
          }
        }
      `
      }
      render={data => (
        <section id="reflections" className={`u__vspacing reflection__recirc ${props.sectionClass || "s__white"}`}>
          <div className="u__container u__spacing">
            <div className="row">
              <div className="col-12">
                <h2 className="h1">{props.header}</h2>
              </div>
              {data.allContentfulReflection.nodes
                .filter(node => slug !== node.slug)
                .map((node, index) => {
                  // only output 4
                  if (index < 4) {
                    return (
                      <div className="col-sm-6 reflection__recirc__post" key={index}>
                        <h3 className="reflection__recirc__post__title h4">
                          <Link to={`/reflections/${node.slug}`}>{node.title}</Link>
                        </h3>
                        <p className="reflection__recirc__post__authors">
                          <Byline authors={node.authors} contentID={node.contentful_id} />
                        </p>
                        <p>{node.body.childMarkdownRemark.excerpt}</p>
                      </div>
                    )
                  } else {
                    return(null)
                  }
              })}
              <div className="col-12">
                <Link to="/reflections" className="reflection__recirc__landing-link h4">All Reflections</Link>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}
