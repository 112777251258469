import React from "react"
import { Link } from "gatsby"

export default function Byline(props) {
  return(
    <>
      <span>By </span>
      {props.authors.map((author, index) => {
        if (index === props.authors.length - 1 && index !== 0) {
          return (
            <span key={`${props.contentID}${author.contentful_id}`}> and <Link to={`/about/team/${author.slug}`}>{author.name}</Link></span>
          )
        } else if (index === 0) {
          return (
            <Link to={`/about/team/${author.slug}`} key={`${props.contentID}${author.contentful_id}`}>{author.name}</Link>
          )
        } else {
          return (
            <span key={`${props.contentID}${author.contentful_id}`}>, <Link to={`/about/team/${author.slug}`}>{author.name}</Link></span>
          )
        }
      })}
    </>
  )
}
